/* ==========================================================================
Theme Name: Logzee
Theme URI: http://mannatstudio.com
Description:  Logzee - A Logistic Cargo HTML Template
Version: 1.0
Author: Mannat Studio
Tags:   business, cargo, chain supply, company, corporate, expedition, freight, logistics, packaging, services, shipping, transport, transportation, trucking, warehousing
========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}
body {
  font-family: var(--font-body);
  font-size: 1rem;
  font-weight: 400;
  color: darken(#999, 20%);
  line-height: 1.6;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
img{
  max-width: 100%;
}
.rev_slider .caption, .rev_slider .tp-caption{
  font-family: var(--font-body) !important;
}
p{
  margin-bottom: 1.25rem;
  font-size: 0.95rem;
}
p:last-child{
  margin-bottom: 0;
}

.container,
.container-fluid{
  padding: 0 15px;
}
.row{
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-right: 15px;
  padding-left: 15px;
}
:root {
  scroll-behavior: inherit;
  --font-body: "Outfit", sans-serif;
  --primary-color: #fe8704;
  --secondary-color: #232135;
  --main-dark-color: #666;
  --bg-light-theme: #fef9f5;
  --bg-white: #FFF;
  --bg-light-gray: #fafafa;
  --shadow-primary: 0px 6px 10px 0px rgba(254, 135, 4, 0.2);
  --shadow-secondary: 0px 6px 10px 0px rgba(254, 135, 4, 0.2);
  --secondary-rgb: 35, 33, 53;
}
/*------------------------------------------*/
/*   LINK SETTINGS
/*------------------------------------------*/
a, button[type="submit"], input[type=text] {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
a:hover {
  color: #666;
  text-decoration: none;
}
a:focus {
  outline: none;
  text-decoration: none;
}
/*------------------------------------------*/
/*    Transition Effect For Different Elements
/*------------------------------------------*/
.top-bar, .icon-box-1, .icon-box-1 .text,
.navbar-brand{
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
/*------------------------------------------*/
/*    Main Navigatio Style
/*------------------------------------------*/
.navbar-brand{
  width: 140px;
  padding: .9rem;
}
.navbar{
  padding: 0 15px;
  background: var(--bg-white);
}
.navbar-expand-md .navbar-nav .nav-link,
.navbar-expand-lg .navbar-nav .nav-link{
  padding: 0.6rem 0.70rem;
  border-radius: 3px;
  font-weight: 600;
  transition: all .3s;
}
.navbar-nav .active .nav-link,
.navbar-nav .active .nav-link:hover,
.navbar-nav .nav-link:hover{
  background: rgba(255,255,255, 0.302);
}

.navbar-nav .nav-link{
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 16px 15px;
  font-weight: 600;
  display: block !important;
}
.dropdown-menu {
  padding: 0;
  min-width: 200px;
  background: #FFF;
  margin: 0;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 991px) {
  .show > .dropdown-menu {
    display: block;
  }

  .dropdown-menu.show {
    display: none;
  }
}
.dropdown-menu:after{
  position: absolute;
  top: -7px;
  left: 20px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #FFF transparent;
}

.dropdown-menu li > .dropdown-menu:after{
  display: none;
}
.dropdown-menu a,
.dropdown-menu li > a{
  font-size: 0.90rem;
  padding: 8px 15px;
  text-transform: capitalize;
  color: #565656;
  border-bottom: 1px solid #ececec;
  text-decoration: none;
  font-weight: 500;
}
.dropdown-menu li:last-child > a{
  border: none;
}
.dropdown-item.active,
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active,
.dropdown-menu > li.show > a{
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
}
.navbar-toggler-icon {
  background-image: url(../images/download.svg);
}

.dropdown-item-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 0.8125rem;
  min-width: 1rem;
  max-width: 1rem;
  margin-right: .5rem;
}
@media (min-width: 991px) {
  .nav-light .navbar-nav .nav-link {
    color: #FFF;
  }
  .nav-light .navbar-nav .active .nav-link,
  .nav-light .navbar-nav .active .nav-link:hover,
  .nav-light .navbar-nav .nav-item:hover > a,
  .nav-light .navbar-nav .nav-link:hover{
    background: rgba(255,255,255, 0.302);
    color: var(--bg-white);
  }
}
/*------------------------------------------*/
/*    Heading Style
/*------------------------------------------*/
.heading-main{
  color:var(--primary-color);
  font-family: var(--font-body);
  font-size: 3.438rem;
  line-height: 3.438rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}
.heading-main span {
  color: rgba(6, 22, 58, 0.5);
  text-transform: uppercase;
  padding-bottom: 5px;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2rem;
}
.heading-left-border{
  color: #717171;
  padding: 0.55rem 1.2rem;
  border-left: 2px solid var(--primary-color);
  margin-bottom: 35px;
  font-weight: 600;
}
.parallax-head.heading-main span{
  color: #FFF;
}
/*------------------------------------------*/
/*    Typography
/*------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
}

/* Header H5 */
h5.h5-xs { font-size: 0.875rem; }   /* 14px */
h5.h5-sm { font-size: 1rem; }       /* 16px */
h5.h5-md { font-size: 1rem; }       /* 16px */
h5.h5-lg { font-size: 1.125rem; }   /* 18px */
h5.h5-xl { font-size: 1.25rem; }    /* 20px */

/* Header H4 */
h4.h4-xs { font-size: 1rem; }       /* 16px */
h4.h4-sm { font-size: 1.125rem; }   /* 18px */
h4.h4-md { font-size: 1.125rem; }   /* 18px */
h4.h4-lg { font-size: 1.25rem; }    /* 20px */
h4.h4-xl { font-size: 1.5rem; }     /* 24px */

/* Header H3 */
h3.h3-xs { font-size: 1rem; }       /* 16px */
h3.h3-sm { font-size: 1.125rem; }   /* 18px */
h3.h3-md { font-size: 1.125rem; }   /* 18px */
h3.h3-lg { font-size: 1.25rem; }    /* 20px */
h3.h3-xl { font-size: 1.5rem; }     /* 24px */

/* Header H2 */
h2.h2-xs { font-size: 1rem; }       /* 16px */
h2.h2-sm { font-size: 1.125rem; }   /* 18px */
h2.h2-md { font-size: 1.125rem; }   /* 18px */
h2.h2-lg { font-size: 1.25rem; }    /* 20px */
h2.h2-xl { font-size: 1.5rem; }     /* 24px */

/* Font Weight = fw */
.fw-3{ font-weight: 300; }
.fw-4{ font-weight: 400; }
.fw-5{ font-weight: 500; }
.fw-6{ font-weight: 600; }
.fw-7{ font-weight: 700; }

/*------------------------------------------*/
/*    Props Images
/*------------------------------------------*/
.img-business-man{
  position: absolute;
  bottom: -100px;
  width: auto;
  left: 0;
}
.forklift-image{
  position: absolute;
  bottom: 0;
  left: -110%;
}

/*------------------------------------------*/
/*    Primary Colour
/*------------------------------------------*/
.txt-blue{
  color: var(--secondary-color);
}
.txt-orange{
  color: var(--primary-color);
}
.txt-ligt-gray{
  color: #424242;
}
.txt-white{
  color: #FFF;
}
.txt-sky-blue{
  color: #fef9f5;
}

/* ANIMATED X */
.navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}
.navbar-toggler .icon-bar {
  width: 22px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.navbar-toggler .icon-bar {
  background-color: #999;
}
.navbar-toggler.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.navbar-toggler.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
/* END ANIMATED X */

/*------------------------------------------*/
/*    Background Color Start
/*------------------------------------------*/
.bg-white{ background: var(--bg-white) !important; }
.bg-light-gray{ background: var(--bg-light-gray); }
.bg-navy-blue{ background: var(--secondary-color); }
.bg-orange{ background: var(--primary-color); }
.bg-light-theme{ background: var(--bg-light-theme); }
.bg-blue-light{background: #ecf2f5;}

/*------------------------------------------*/
/*    Background Color Start
/*------------------------------------------*/
.bg-img-6{
  background-image: url(../images/bg_6.jpg);
  background-repeat: no-repeat;
  background-position: center center;
}
.bg-img-7{
  background-image: url(../images/bg_7.jpg);
  background-repeat: no-repeat;
  background-position: left center !important;
}
.bg-img-7{
  background-image: url(../images/breadcrumbs_bg.jpg);
  background-repeat: no-repeat;
  background-position: left center !important;
}
.bg-img-8{
  background-image: url(../images/bg_8.jpg);
  background-repeat: no-repeat;
  background-position: left center !important;
}
/*------------------------------------------*/
/*    Background Images
/*------------------------------------------*/
.bg-wave{
  background-image: url(../images/bg-wave.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
}
/*------------------------------------------*/
/*    Button Styles
/*------------------------------------------*/
.btn-center{
  margin-top: 70px;
  margin-bottom: -20px;
}
.btn-theme{
  color: #FFF !important;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 1.9rem;
  font-size: 0.875rem;
  display: inline-block;
  display: inline-flex;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  align-items: center;
  position: relative;
  cursor: pointer;

  -webkit-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  -moz-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);

  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  border-radius: 70px;
}

.btn-theme:before {
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0;
  left: 60%;
  right: 60%;
  bottom: 0;
  opacity: 0;
  content: '';
  z-index: -2;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

}
.btn-theme:hover::before {
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.btn-theme:focus,
.btn-theme:active{
  outline: none;
  box-shadow: none;
}
.btn-theme i,
[class*="link-"] i{
  padding-left: 5px;
  line-height: inherit;

  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.btn-theme.icon-left i,
[class*="link-"].icon-left i{
  padding-right: 9px;
  padding-left: 0;
}
.btn-theme.inverse-icon-org i{
  color: var(--primary-color);
}
.btn-theme.inverse-icon-blk i{
  color: #000;
}
.btn-theme.light{
  color: #333 !important;
}
.btn-theme.no-shadow{
  box-shadow: none !important;
}

/* Links Text Start */
.link-oragne{
  color: var(--primary-color);
  font-weight: 700;
}
.link-blue {
  color: var(--secondary-color);
  font-weight: 700;
}
.link-dark {
  color: #333;
  font-weight: 700;
}
.link-light {
  color: #FFF;
  font-weight: 700;
}
.link-oragne:hover {
  color: var(--secondary-color);
}
.link-blue:hover {
  color: var(--primary-color);
}
.link-dark:hover {
  color: #000;
}
.link-light:hover {
  color: var(--primary-color);
}
/* Links Text End */

/* Button Border Start */
.bordered{
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  background: transparent !important;
}
.bordered.bg-orange{
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.bordered.bg-navy-blue{
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.bordered.bg-light-theme{
  border-color: #f1fbff;
  color: #f1fbff;
}
.bordered.bg-white{
  border-color: #FFF;
  color: #FFF;
}
.bordered.bg-white:hover{
  border-color: #FFF;
  background: #FFF !important;
  color: #333 !important;
}

.btn-theme.icon-only i{
  padding: 0;
}
/* Button Border End */

/*  Button Hover Styles */
.btn-theme:hover{
  /*background: var(--primary-color);
  color: #FFF !important;*/
  box-shadow: none;

}
/*------------------------------------------*/
/*    Accordian Style
/*------------------------------------------*/
.theme-accordian .panel-group .panel{
  border-radius:0;
  border:none;
  box-shadow:none;
}
.theme-accordian .panel-default > .panel-heading{
  background-color:#f07039;
  border-radius:0;
  padding:0;
}
.theme-accordian .panel-heading{
  background:#47bdcc;
  padding:0;
  border-radius:3px;
}
.theme-accordian .panel-title > a{
  padding:12px 15px;
  outline:0;
  display:block;
  color:#FFF;
  text-transform:capitalize;
  outline:none;
  position:relative;
}
.theme-accordian .panel-title > a i{
  margin-right:8px;
}
.theme-accordian .panel-body, .theme-accordian-rounded .panel-body{
  padding:0 0 20px 0;
  font-size: 0.827rem;
}
.panel{
  padding: 0 0 0 20px;
}
.panel, .panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body{
  background:none;
  border:none;
  box-shadow:none;
}
.theme-accordian.white{
  background:none;
}
.theme-accordian.white .panel-heading{
  background:#FFF;
}
.theme-accordian.white .panel-title > a{
  color:#343434;
}
.theme-accordian.white .panel-title > a i{
  color:#47bdcc;
}
.theme-accordian-rounded .panel-title > a i{
  text-align: center;
  margin-right: 12px;
  color: var(--primary-color);
  font-size: 0.875rem;
  position: absolute;
  left: -26px;
  top: 2px;

  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
}
.theme-accordian-rounded .panel-heading{
  padding:0 0 5px 0;

}
.panel-title{
  font-size:1rem;
  position: relative;
}
.theme-collapse{
  padding: 0 0 0 30px;
}
.theme-collapse .toggle {
  color: #1c1c1c;
  font-size: 0.9rem;
  padding-bottom: 15px;
  text-transform: capitalize;
  position: relative;
  padding-left: 0;
  cursor: pointer;
  font-weight: 700;
}
.theme-collapse .toggle i{
  position: absolute;
  top: 3px;
  left: -34px;
  font-size: 1rem;
  color: var(--primary-color);
  transform: rotate(0deg);
  transition: all 0.15s linear;
}
.theme-collapse .toggle.arrow-down{
  color: var(--primary-color);
}
.theme-collapse .toggle.arrow-down i{
  transform: rotate(180deg);
}
.theme-collapse .content{
  padding: 0 0 15px 0;
}
.theme-collapse a {
  display: block;
  font-size: 14px;
  color: #7b7b7b;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.theme-collapse a:hover{
  color: #24547f;
}
.theme-collapse a:last-child{
  margin: 0;
}
/* Right Icon With Border */
.arrow-right-border.theme-collapse {
  padding: 0;
}
.arrow-right-border.theme-collapse .toggle i {
  left: auto;
  right: 0;
}
.arrow-right-border.theme-collapse .toggle {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
  padding-right: 20px;
}
/* Right Icon With Border */
/* Accordian Style Capsules */
.capsules.theme-collapse {
  padding: 0;
}
.capsules.theme-collapse .toggle{
  background: var(--secondary-color);
  margin-bottom: 15px;
  padding: 10px 10px 10px 40px;
  color: #FFF;
  font-size: 0.875rem;

  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.capsules.theme-collapse .readonly{
  background: var(--secondary-color);
  margin-bottom: 15px;
  padding: 10px 10px 10px 40px;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: bold;

  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.capsules.theme-collapse .toggle i {
  left: 15px;
  color: #FFF;
  transform: translateY(-50%) rotate(0deg);
  top: 50%;
}
.capsules.theme-collapse .toggle.arrow-down{
  background: var(--primary-color);
}
.capsules.theme-collapse .toggle i {
  left: 15px;
  color: #FFF;
  transform: translateY(-50%) rotate(0deg);
  top: 50%;
}

.capsules.theme-collapse .toggle.arrow-down i {
  transform: translateY(-50%) rotate(180deg);
}

.capsules.theme-collapse .content {
  padding: 0 20px 15px 25px;
}
/* Accordian Style Capsules */

/* BS Accordian Style */
.bs-theme-accordian .accordion-button:not(.collapsed){
  background-color: var(--primary-color);
  color: var(--bg-white);
}
.bs-theme-accordian .accordion-button:focus{
  box-shadow: none;
  border: none;
}
.bs-theme-accordian .accordion-item:first-of-type .accordion-button,
.bs-theme-accordian .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bs-theme-accordian .accordion-item:last-of-type .accordion-button,
.bs-theme-accordian .accordion-item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.bs-theme-accordian .accordion-item {
  border: 1px solid #dddddd;
}
.bs-theme-accordian .accordion-item:not(:first-of-type) {
  border-top: 0;
}
.bs-theme-accordian .accordion-button::after,
.bs-theme-flush .accordion-button::after{
  font-family: IcoFont!important;
  content: "\eac8";
  background-image: none;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  position: absolute;
  right: 020px;
  top: 50%;
  font-size: 1.5rem;
  transform: translateY(-50%);
}
.bs-theme-accordian .accordion-button:not(.collapsed)::after,
.bs-theme-flush .accordion-button:not(.collapsed)::after{
  content: "\eacb";
}
.bs-theme-flush .accordion-button:focus{
  box-shadow: none;
}
.bs-theme-flush .accordion-button:not(.collapsed){
  background-color: transparent;
  color: var(--primary-color);
}
.bs-theme-flush .accordion-button,
.bs-theme-flush .accordion-body{
  padding: 1rem 0;
}
.bs-theme-flush .accordion-button:not(.collapsed) {
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
/* BS Accordian Style */

/*------------------------------------------*/
/*    Tabbing Style
/*------------------------------------------*/
.theme-tabbing.nav-pills .nav-link {
  border-radius: 5.25rem;
  background: var(--secondary-color);
  color: #FFF;
  padding: .5rem 1.5rem;
  margin-right: 15px;
}
.theme-tabbing.nav-pills .nav-link.active {
  background: var(--primary-color);
  color: #FFF;
  box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
}
.theme-tabbing.nav-pills .nav-link i{
  padding-right: 6px;
}
.tab-content.theme-tabbing .tab-pane{
  padding-top: 1.25rem !important;
}

/*------------------------------------------*/
/*    Our Skills Style
/*------------------------------------------*/
.piecharts h2 {
  font-size:45px;
  border:0;
  line-height: 40px;
  margin-bottom: 30px;
}
.piecharts p {
  margin:0
}
.piecharts .container {
  position: relative;
  z-index: 5;
}
.piecharts .col-md-4 {
  text-align:center;
}
.chart {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 0px 0 15px 0;
  text-align: center;
}
.chart.first {
  margin-left:0;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.percent {
  display: inline-block;
  line-height: 100px;
  z-index: 2;
  font-size:18px;
  font-weight: 600;
  color:#59585b;
}
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}
.angular {
  margin-top: 100px;
}
.angular .chart {
  margin-top: 0;
}
.skill-name {
  font-size: 14px;
  font-weight: 600;
  text-align:center;
  text-transform:capitalize;
  margin-bottom:20px;
  width: 100%;
  color: var(--secondary-color);
}

/*------------------------------------------*/
/*    Animated Bars Style
/*------------------------------------------*/
.skillbar-wrap{
  padding: 0 0 20px 0;
  color: #7d7d7d;
  font-weight: 500;
}
.skillbar-wrap .clearfix {
  margin-bottom: 18px;
  font-weight: 700;
  color: var(--secondary-color);
}
.skillbar-percent{
  position: absolute;
  padding: 2px 8px;
  font-size: 0.8125rem;
  background: var(--secondary-color);
  top: -35px;
  font-weight: 600;
  color: #FFF;
  right: 0;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.skillbar {
  position:relative;
  display:block;
  width:100%;
  background-color: rgba(6, 22, 58, 0.2);

  height:2px;

  -webkit-transition:0.4s linear;
  -moz-transition:0.4s linear;
  -ms-transition:0.4s linear;
  -o-transition:0.4s linear;
  transition:0.4s linear;
}
.skillbar-bar {
  height:2px;
  width:0px;
  background:var(--primary-color);
  margin:10px 0 0 0;
}
.skill-bar-percent {
  color:#303030;
}

/*------------------------------------------*/
/*    Alert Style
/*------------------------------------------*/
.alert-theme{
  border: 1px solid var(--primary-color);
}

/*------------------------------------------*/
/*    Overlay Styles
/*------------------------------------------*/
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  left: 0;
  background: rgba(6,22,58,0.9);
}
/*------------------------------------------*/
/*   Search Popup
/*------------------------------------------*/
/* Overlay closing cross */
.overlay .overlay-close i{
  padding: 0 !important;
  /*width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    color: #FFF;
    outline: none;
    z-index: 100;
    line-height: 80px;
    text-align: center;*/
}

.overlay form {
  text-align: center;
  position: relative;
  top: 50%;
  font-size: 22px;
  color: #b2b2b2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overlay .form-inner {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  position: relative;
}
.overlay .form-inner input[type="text"]{
  box-shadow: none !important;
  border: none;
}
/* Effects */
.overlay-hugeinc {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, visibility 0s 0.5s;
}
.overlay-hugeinc.open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.overlay-hugeinc form {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.overlay-hugeinc form .form-inner {
  opacity: 0.4;
  -webkit-transform: translateY(-25%) rotateX(35deg);
  transform: translateY(-25%) rotateX(35deg);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}
.overlay-hugeinc form .form-inner {
  width: 450px;
}
.overlay-hugeinc.open form .form-inner {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.overlay-hugeinc form .form-inner-div {
  padding: 0.75rem;
  background: #FFF;
  border-radius: 70px;
  min-width: 100%;
}
.overlay-hugeinc form .form-inner i.icofont-search{
  padding: 0 1rem 0 0.5rem;
}
.overlay-hugeinc form .form-inner .overlay-close{
  padding: 0 0.5rem 0 0.8rem;
}
.overlay-hugeinc.close form .form-inner {
  -webkit-transform: translateY(25%) rotateX(-35deg);
  transform: translateY(25%) rotateX(-35deg);
}
/*------------------------------------------*/
/*   BACKGROUND SETTINGS
/*------------------------------------------*/
.bg-scroll {
  width: 100%;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-fixed {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*------------------------------------------*/
/*    Callout Style Start
/*------------------------------------------*/
.contact-callout{
  color: #fefefe;
}
.callout-style-1{
  font-size: 14px;
  color: #FFF;
}
.center-text{
  padding: 0 4rem;
}

/*------------------------------------------*/
/*    Top Bottom Spacing
/*------------------------------------------*/
.wide-tb-100{ padding-top: 100px; padding-bottom: 100px; }
.wide-tb-90{ padding-top: 90px; padding-bottom: 90px; }
.wide-tb-80{ padding-top: 80px; padding-bottom: 80px; }
.wide-tb-70{ padding-top: 70px; padding-bottom: 70px; }
.wide-tb-70{ padding-top: 70px; padding-bottom: 70px; }
.wide-tb-60{ padding-top: 60px; padding-bottom: 60px; }
.wide-tb-50{ padding-top: 50px; padding-bottom: 50px; }
.wide-tb-40{ padding-top: 40px; padding-bottom: 40px; }
.wide-tb-30{ padding-top: 30px; padding-bottom: 30px; }


/*------------------------------------------*/
/*    Icon Box Style
/*------------------------------------------*/

/* Box Style One */
.icon-box-1{
  background: #071639;
  color: #FFF;
  overflow: hidden;
  position: relative;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.icon-box-1 img{
  opacity: 0.5;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.icon-box-1 .text{
  position: absolute;
  text-align: center;
  bottom: 25px;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
}
.icon-box-1 .text i{
  font-size: 3.5rem;
  display: block;
  margin-bottom: 0.8rem;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;

}
.icon-box-1:hover{
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.32);
  cursor: pointer;
}

.icon-box-1:hover img{
  opacity: 0.3;

  transform: scale(1.2);

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.icon-box-1:hover .text{
  transform: translateY(-15px);
}
.icon-box-1:hover .text i{
  padding-bottom: 7px;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

/* Box Style Two */
.icon-box-2 .media{
  display: flex;
}
.icon-box-2 .service-icon{
  flex-shrink: 0;
}
.icon-box-2 .service-inner-content{
  flex-grow: 1;
}
.service-icon{
  border-radius: 50%;
  margin-right: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: var(--shadow-primary);
  width: 65px;
  height: 65px;
  z-index: 101;
  text-align: center;
  font-size: 24px;
  color: var(--primary-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service-inner-content{
  font-size: 14px;
  color: #6f6f6f;
  line-height: 1.571;
}
.service-inner-content h4{
  color: var(--secondary-color);
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 15px;
}

/* Icon Box 3 */
.icon-box-3 .media{
  display: flex;
}
.icon-box-3 .service-icon{
  height: 81px;
  width: 81px;
  margin-right: 2rem;
  border-style: solid;
  border-width: 4px;
  border-color: rgba(6, 22, 58, 0.11);
  background: transparent;
  position: relative;
  z-index: 10;
  margin-top: 25px;
  border-radius: 0;
  box-shadow: none;
  flex-shrink: 0;
}
.icon-box-3 .service-inner-content{
  flex-grow: 1;
}
.icon-box-3 .service-icon:before{
  height: 81px;
  width: 81px;
  position: absolute;
  content: '';
  border-style: solid;
  border-width: 4px;
  border-color: rgba(6, 22, 58, 0.11);
  background: transparent;
  z-index: -1;
  left: -20px;
  top: -20px;
}
.icon-box-3 .service-icon i{
  height: 100%;
  font-size: 40px;
  color: var(--secondary-color);
  width: 100%;
  position: absolute;
  left: 0;
  line-height: 80px;
}

.icon-box-3.bg-light-theme .service-icon i{
  background: #fef9f5;
}
.icon-box-3[class*="bg-"]{
  background: transparent;
}
/* Icon Box 3 */

/* Icon Box 4 */
.icon-box-4{
  text-align: center;
  background-color: #FFF;
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.05);
  padding: 2rem 1.25rem;
  position: relative;
  cursor: pointer;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.icon-box-4:after{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 5px;
  background: transparent;
  width: 100%;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 1;
}
.icon-box-4:before{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 5px;
  background: var(--primary-color);
  width: 0;
  transition: width .3s;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}
.icon-box-4:hover{
  transform: translateY(-15px);
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.2);
}

.icon-box-4:hover:before {
  width: 100%;
}
.icon-box-4 i{
  font-size: 40px;
  color: var(--secondary-color);
  margin-bottom: 15px;
  display: block;
}
/* Icon Box 4 */

/* Icon Box 5 */
.icon-box-5{
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.icon-box-5 i{
  font-size: 36px;
}
/* Icon Box 5 */

/* Icon Box With Image */
.icon-box-with-img{
  position: relative;
  overflow: hidden;
  cursor: pointer;

  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;

  -webkit-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.32);

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.icon-box-with-img:hover{
  transform: translateY(-15px);
  box-shadow: none;
}
.icon-box-with-img img{
  opacity: 0.5;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.icon-box-with-img .text {
  position: absolute;
  color: #FFF;
  text-align: center;
  top: 50%;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  transform: translateY(-50%);
  text-transform: uppercase;
}
/* Icon Box With Image */

/* Icon Box Six */
.icon-box-6{
  border: 1px solid #eae9e9;
  padding: 1.25rem 1.25rem 0;
  text-align: center;
  margin-bottom: 25px;
}
.icon-box-6 > i{
  font-size: 3.5rem;
  color: var(--primary-color);
  margin-bottom: 8px;
  display: block;
}
.icon-box-6 > h3{
  font-weight: 600;
  margin-bottom: 19px;
}
.icon-box-6 .btn-arrow,
.icon-box-7 .btn-arrow{
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 2.25rem;
  color: #FFF;
  border-radius: 50%;
  display: inline-block;
  bottom: -25px;
  position: relative;
}
.icon-box-6 .btn-arrow:hover,
.icon-box-7 .btn-arrow:hover{
  background: var(--primary-color);
  color: #FFF;
}
.icon-box-6 p{
  margin-bottom: 0;
}
/* Icon Box Six */

/* Icon Box Seven */
.icon-box-7{
  text-align: center;
  background: var(--primary-color);
  margin-bottom: 45px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
}
.icon-box-7 img{
  opacity: 0.5;
  width: 100%;
  border-radius: 4px;
}
.icon-box-7 h3{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  text-align: center;
  top: 50%;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
}
.icon-box-7 .btn-arrow {
  position: absolute;
  bottom: -25px;
  background: #333;
  left: 50%;
  transform: translateX(-50%);
}
/* Icon Box Seven */

/*------------------------------------------*/
/*    Team Section
/*------------------------------------------*/
.team-section-one,
.team-section-two{
  text-align: center;
}
.team-section-one img{
  width: 180px;
  margin-bottom: 25px;
  box-shadow: 0px 6px 10px 0px rgba(254, 135, 4, 0.2);
  border: 10px solid #FFF;
}

.team-section-two{
  text-align: center;
}
.team-section-two img{
  width: 90%;
  box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  margin-bottom: 25px;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}
.team-section-one h4,
.team-section-two h4{
  font-weight: 600;
}
.team-section-bottom:after{
  position: absolute;
  width: 100%;
  content: "";
  height: 175px;
  left: 0;
  bottom: 0;
  background: var(--bg-light-theme);
  z-index: -1;
}

/*------------------------------------------*/
/*    Spacer For Gap
/*------------------------------------------*/
.spacer-70{ min-height: 70px; }
.spacer-60{ min-height: 60px; }
.spacer-50{ min-height: 50px; }
.spacer-40{ min-height: 40px; }
.spacer-30{ min-height: 30px; }
.spacer-20{ min-height: 20px; }
.spacer-10{ min-height: 10px; }

/*------------------------------------------*/
/*    Background Overlay
/*------------------------------------------*/
.pos-rel{
  position: relative;
}
.bg-overlay{
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.5);
  left: 0;
}
.bg-overlay + .container{
  position: relative;
  z-index: 99;
}
.bg-overlay.blue{ background-color: var(--secondary-color); }
.bg-overlay.black{ background-color: #000; }
.bg-overlay.white{ background-color: #FFF; }

.bg-overlay.opacity-30{ opacity: 0.3; }
.bg-overlay.opacity-40{ opacity: 0.4; }
.bg-overlay.opacity-50{ opacity: 0.5; }
.bg-overlay.opacity-60{ opacity: 0.6; }
.bg-overlay.opacity-70{ opacity: 0.7; }
.bg-overlay.opacity-80{ opacity: 0.8; }


/*------------------------------------------*/
/*    Tracking Form
/*------------------------------------------*/
.tracking{
  background: url(../images/map-bg.png) no-repeat top center;
  padding-bottom: 250px;
  display: flex;
  align-items: center;
}
.tracking .form-control::placeholder{
  color: var(--bg-white);

}
.tracking input[type="text"]{
  border-width: 0 0 1px 0;
  border-style: solid;
  font-size: 0.875rem;
  background: transparent;
  border-color: #d0d0d0;
  padding: 10px 0;
  border-radius: 0;
  box-shadow: none;
  margin-right: 1.5rem;
}
.tracking.parallax{
  background-image: url(../images/bg_5.jpg);
  background-repeat: no-repeat;
  background-position: center center !important;
  padding-bottom: 100px;
}

.tracking.parallax .form-inline{
  display: flex;
}

.tracking.parallax input[type="text"]{
  color: #FFF;
}

/*------------------------------------------*/
/*    Footer Subscribe
/*------------------------------------------*/
.footer-subscribe .flex-nowrap{
  position: relative;
}
.footer-subscribe .head{
  font-size: 0.875rem;
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 700;
}
.footer-subscribe .head span{
  color: #333;
  font-size: 0.75rem;
}

.footer-subscribe input[type="text"] {
  border: 1px solid var(--main-dark-color);
  border-radius: 55px;
  font-size: 14px;
  padding: 12px 26% 12px 20px;
  background: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  box-shadow: none !important;
}
.footer-subscribe .btn-theme{
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  z-index: 99;
}
.widget-wrap .footer-subscribe{
  position: relative;
}
.widget-wrap .footer-subscribe .btn-theme{
  width: 90px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

/*------------------------------------------*/
/*    Twitter Tweets
/*------------------------------------------*/
.twitter-feed{
  background: #fafafa;
  padding: 20px;
  font-size: 0.8125rem;
}
.icon-feed{
  font-size: 60px;
  padding: 1.5rem;
}
.tweet-stream .tweet_list, .tweet-stream .tweet_list li {
  margin:0;
  list-style:none;
  font-size:13px;
  line-height:25px;
  padding:0;
}
.tweet-stream .tweet_list li{
  border-bottom:1px solid #ddd;
  padding:0 0 10px 0;
  margin:0 0 10px 0;
}
.tweet-stream .tweet_list li:last-child{
  border:none;
  margin:0;
  padding:0;
}
.tweet-stream .tweet_list li .tweet_text, .tweet-stream .tweet_list li .tweet_time {
  display:block
}
.tweet-stream .tweet_list li .tweet_time {
  font-size:11px;
  margin-top:0px
}
.tweet-stream .tweet_list li .tweet_time a {
  font-weight:400;
}

/*------------------------------------------*/
/*    Blockquotes Style
/*------------------------------------------*/
.style-one {
  font-size: 1.25rem;
  color: #333;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
}
.style-one:after{
  position: absolute;
  top: 0;
  left: -55px;
  content: "\efcd";
  font-size: 36px;
  font-family:IcoFont;
  color: rgba(6, 22, 58, 0.1);
  line-height: 0.833;
}
.style-one .blockquote-footer::before{
  display: none;
}
.style-one cite{
  font-size: 0.875rem;
  font-style: normal;
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 15px;
  display: block;
}
/*------------------------------------------*/
/*    Counter Text Animation
/*------------------------------------------*/
.counter-bg{
  background-image: url(../images/bg_1.jpg);
  background-repeat: no-repeat;
}
.counter-style-1{
  color: #FFF;
  text-align: center;

}
.counter-style-1 i {
  font-size: 3rem;
}
.counter-style-1 span {
  font-size: 3rem;
  color: #fef9f5;
  font-weight: 700;
}

.counter-style-1.light-bg{
  color: #333333;
  background: #ffffff;
  padding: 2rem 0;
  border-bottom: 4px solid var(--secondary-color);
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.05);
}
.counter-style-1.light-bg span{
  color: var(--primary-color);
}
.counter-style-1.light-bg i{
  color: var(--secondary-color);
}
/*------------------------------------------*/
/*    Client Logo
/*------------------------------------------*/
.clients-bg {
  background-image: url(../images/bg_3.jpg);
  background-repeat: no-repeat;
  background-position: center center;
}
.clients-bg .item{
  background: #FFF;
}
.clients-bg .heading-main span,
.clients-bg .heading-main{
  color: #FFF;
}
.clients-bg .heading-main{
  margin-bottom: 30px;
}
.clients-bg .owl-carousel .owl-item{
  text-align: center;
}
.clients-bg .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/*------------------------------------------*/
/*    Free Quote Form
/*------------------------------------------*/
::-webkit-input-placeholder {
  /* Edge */
  color: #333;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}

::placeholder {
  color: #333;
}
.free-quote{
  background-image: url(../images/bg_2.jpg);
  background-repeat: no-repeat;
  background-position: top;
  margin-bottom: 70px;
}
.free-quote-form{
  border-radius: 7px;
  background-color: #FFF;
  box-shadow: 0px 18px 76px 0px rgba(0, 0, 0, 0.14);
  padding: 40px 20px;
  margin-bottom: -70px;
}
.rounded-field input[type="text"],
.rounded-field input[type="email"],
.rounded-field input[type="date"],
.rounded-field select,
.rounded-field textarea,
.rounded-field .nice-select{
  border: 1px solid #dfdfdf;
  padding: 10px 20px;
  min-height: 44px;
  font-size: 0.875rem;
  color: #333;

  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}
.rounded-field input[type="text"]:focus,
.rounded-field input[type="email"]:focus,
.rounded-field input[type="date"],
.rounded-field select:focus,
.rounded-field textarea:focus{
  box-shadow: none;
}

.rounded-field input[type="date"]{

}

.rounded-field textarea:focus{
  border: 1px solid #dfdfdf;
}
.rounded-field .custom-select{
  background-position: 93% center;
}
.rounded-field textarea{
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  font-size: 0.875rem;
}
.free-quote-alt{
  background-image: url(../images/bg_4.jpg) !important;
}

.gray-field.rounded-field input[type="text"],
.gray-field.rounded-field input[type="email"],
.gray-field.rounded-field input[type="date"],
.gray-field.rounded-field select,
.gray-field.rounded-field textarea,
.gray-field .nice-select {
  border-radius: 0;
  background-color: #fcfcfc;
  box-shadow: none !important;
  border: 1px solid #dfdfdf !important;
}

/*------------------------------------------*/
/*    Owl Slider Arrow
/*------------------------------------------*/
.owl-nav{
  text-align: center;
  margin-top: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next{
  position:absolute;
  height:40px;
  width:40px;
  outline: none;
  display:inline-block;
  color:#4b1616;
  font-size: 48px;
  font-family:"FontAwesome";
  line-height:44px;
  margin:-20px 5px;

  border-radius:20px;
  -moz-border-radius:20px;
  -webkit-border-radius:20px;

  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.owl-carousel .owl-nav button:hover,
.owl-carousel .owl-nav button:hover{
  color:var(--primary-color);
}

.owl-carousel .owl-dots{
  text-align: center;
  margin-top: 20px;
}
.owl-carousel .owl-dots button{
  outline: none;
}

.owl-carousel .owl-nav button.owl-prev{
  left: -25px;
}
.owl-carousel .owl-nav button.owl-next{
  right: -25px;
}

.owl-carousel .owl-dots button span{
  display: block;
  width: 10px;
  height: 10px;
  margin: 5px 2px;
  filter: Alpha(Opacity=50);/*IE7 fix*/
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #FFF;
  border:2px solid #333;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  outline: none;
}
.owl-carousel .owl-dot.active span,
.owl-controls.clickable .owl-dot:hover span, .owl-carousel .owl-dot:hover span{
  background:#FFF;
  border:2px solid #f0738a;
}


/*------------------------------------------*/
/*    Twitter/Flickr Style
/*------------------------------------------*/
.tweet-stream .tweet_list, .tweet-stream .tweet_list li {
  margin:0;
  list-style:none;
  font-size:13px;
  line-height:25px;
  padding:0;
}
.tweet-stream .tweet_list li{
  border-bottom:1px solid #ddd;
  padding:0 0 10px 0;
  margin:0 0 10px 0;
}
.tweet-stream .tweet_list li:last-child{
  border:none;
  margin:0;
  padding:0;
}
.tweet-stream .tweet_list li .tweet_text, .tweet-stream .tweet_list li .tweet_time {
  display:block
}
.tweet-stream .tweet_list li .tweet_time {
  font-size:11px;
  margin-top:0px
}
.tweet-stream .tweet_list li .tweet_time a {
  font-weight:400;
}
.photo-thumbs {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.photo-thumbs li {
  list-style: none;
  float: left;
  margin: 3px;
  padding: 0px;
  background: #FFF;
  width:70px;
}
.photo-thumbs li img {
  display: block;
}
.photo-thumbs li a img {
  border: none;
}

/*------------------------------------------*/
/*    List Icon Style
/*------------------------------------------*/
.icons-listing li{
  padding: 0 0 20px 35px;
  position: relative;
}
.icons-listing li i{
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
}
.icons-listing.theme-orange li i{
  color: var(--primary-color);
}
.icons-listing.theme-blue li i{
  color: #264455;
}
.icons-listing.theme-light li i{
  color: #cccccc;
}
.icons-listing.theme-dark li i{
  color: #0d0d0d;
}
.icons-listing.w-half{
  display: flex;
  flex-wrap: wrap;
}
.icons-listing.w-half li{
  flex: 1 1 50%;
}

/*------------------------------------------*/
/*    Blockquote Style
/*------------------------------------------*/
.blockquote-1 footer, .blockquote-2 footer, .blockquote-3 footer{
  padding:0;
  background:none;
}
.blockquote-1{
  border:none;
  padding:0 0px 0 60px;
  margin:0 0 30px 0;
  position:relative;
  font-size:18px;
  font-family: 'Roboto Slab', serif;
  color:#323232;
}
.blockquote-1:before{
  position:absolute;
  font-size:48px;
  font-family:'FontAwesome';
  content:"\f10d";
  left:0;
  top:20px;
  color:#47bdcc;
}
.blockquote-1 footer cite{
  font-style:inherit;
  font-size:14px;
  color:#47bdcc;
  font-family: 'Open Sans', sans-serif;
}
.blockquote-2, .blockquote-3{
  padding:2rem 2rem 2rem 6rem;
}
.blockquote-2 {
  background: var(--secondary-color);
  border: none;
  position: relative;
  font-size: 1.5rem;
  color: #FFF;
  font-weight: 700;
  line-height: 1.4;
}
.blockquote-3 {
  background: #ffffff;
  border-left: 5px solid var(--primary-color);
  position: relative;
  font-size: 1.5rem;
  color: var(--secondary-color);
  font-weight: 700;
  line-height: 1.4;
}
.blockquote-2 footer cite, .blockquote-3 footer cite{
  font-style:inherit;
  font-size:14px;
  color:var(--primary-color);
}
.blockquote-2 footer cite{
  color: #FFF;
}
.blockquote-2 footer, .blockquote-3 footer{
  text-align:right;
}
.blockquote-2:before, .blockquote-3:before{
  position:absolute;
  font-size:2.25rem;
  font-family:'FontAwesome';
  content:"\f10d";
  left:40px;
  top:35px;
  color:var(--primary-color);
}
.blockquote-2:before{
  color: rgba(255, 255, 255, 0.4);
}

/*------------------------------------------*/
/*    Dropcap Style
/*------------------------------------------*/
.dropcap {
  font-size:32px;
  margin:0px 15px 0 0;
  color:#303030;
}
.dropcap2 {
  color:#FFFFFF;
  margin-right:15px;
  width:50px;
  height:50px;
  line-height:50px;
  text-align:center;
}
.dropcap, .dropcap2 {
  float:left
}
.dropcap, .dropcap2 {
  font-weight:700
}
.dropcap, .dropcap2 {
  font-size:32px
}


/*------------------------------------------*/
/*    FAQs
/*------------------------------------------*/
@media (min-width: 767.99px) {
  .faqs [class*="col-md-"]{
    margin-bottom: 30px;
  }
  .faqs [class*="col-md-"]:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
/*------------------------------------------*/
/*    Page Loader
/*------------------------------------------*/
#pageloader {
  background:#FFF;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  color:#333;
  font-style:italic;
  font-size:14px;
  font-weight:400;
  text-align:center;
  top: 0;
  background: var(--primary-color) url(../images/bg-wave.svg) no-repeat;
  background-size: contain;
  background-position: bottom center;
}
.loader-item {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  width: auto;
  transform: translate(-50%,-50%);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  35% {
    transform: rotate(90deg);
  }
  45% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  85% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(90deg);
  }
  35% {
    -webkit-transform: rotate(90deg);
  }
  45% {
    -webkit-transform: rotate(180deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  85% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
    transform: scaleX(1);
  }
  10% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.8);
  }
  35% {
    transform: scaleX(0.8);
  }
  45% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(0.8);
  }
  85% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scaleX(1);
  }
  10% {
    -webkit-transform: scaleX(1);
  }
  20% {
    -webkit-transform: scaleX(0.8);
  }
  35% {
    -webkit-transform: scaleX(0.8);
  }
  45% {
    -webkit-transform: scaleX(1);
  }
  60% {
    -webkit-transform: scaleX(1);
  }
  75% {
    -webkit-transform: scaleX(0.8);
  }
  85% {
    -webkit-transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}
.loader {
  box-sizing: content-box;
  height: 40px;
  margin: 0;
  position: relative;
  width: 70px;
}
.loader:before {
  border: 2px solid #FFF;
  box-sizing: content-box;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0;
}
.loader .spin {
  -webkit-animation: spin 2.25s linear infinite;
  animation: spin 2.25s linear infinite;
  height: 14px;
  left: 28px;
  position: absolute;
  top: 13px;
  width: 14px;
}
.loader .spin:before {
  border: 3px solid #FFF;
  box-sizing: content-box;
  content: '';
  height: 14px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  width: 14px;
}
.loader .bounce {
  -webkit-animation: bounce 2.25s linear infinite;
  animation: bounce 2.25s linear infinite;
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.loader .bounce:after, .loader .bounce:before {
  box-sizing: content-box;
  content: '';
  height: 10px;
  position: absolute;
  top: 13px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}
.loader .bounce:before {
  border-bottom: 3px solid #FFF;
  border-left: 3px solid #FFF;
  left: 0;
}
.loader .bounce:after {
  border-right: 3px solid #FFF;
  border-top: 3px solid #FFF;
  right: 0;
}
/*------------------------------------------*/
/*    Scroll To Top Style
/*------------------------------------------*/
#mkdf-back-to-top {
  display: block;
  visibility: hidden;
  position: fixed;
  left: 25px;
  bottom: 60px;
  border-radius: 50%;
  margin: 0;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  opacity: 0;
  z-index: 99;
  color: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  -moz-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
  background-color: var(--secondary-color);
  box-sizing: border-box;
  -webkit-transition: opacity .2s ease-out,visibility .2s ease-out,color .2s ease-out, background-color .2s ease-out, right .1s ease-out;
  -moz-transition: opacity .2s ease-out,visibility .2s ease-out,color .2s ease-out, background-color .2s ease-out, right .1s ease-out;
  transition: opacity .2s ease-out,visibility .2s ease-out,color .2s ease-out, background-color .2s ease-out, right .1s ease-out;
}
#mkdf-back-to-top:hover{
  background-color: var(--primary-color);
}
#mkdf-back-to-top:hover i{
  animation: toUpFromDown 0.3s forwards;
}
#mkdf-back-to-top.on {
  opacity: 1;
  visibility: visible;
}
#mkdf-back-to-top:hover .mkdf-icon-stack {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}
#mkdf-back-to-top:hover .mkdf-back-to-top-text {
  opacity: 0;
}

@-webkit-keyframes toUpFromDown {
  49% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}
.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  text-indent: -9999px;
  background: url('../images/image-placeholder.jpg') no-repeat;
  background-color: #000;
}
.scrollup.open{
  display: block;
}

/*------------------------------------------*/
/*    About Page Style
/*------------------------------------------*/
.about-bg{
  background-image: url(../images/map-bg.png);
  background-repeat: no-repeat;
  background-position: 100% -73%;
  border-radius: 5px;
  overflow: hidden;
}
.bg-with-text{
  background: url(../images/bg-text.jpg) no-repeat center right;
  background-size: cover;
  border-radius: 5px;
  text-align: center;
  color: #FFF;
  font-size: 1.125rem;
  padding: 0 1.5rem;
  font-weight: 300;
  line-height: 2;
}
.video-popup{
  padding: 11rem 0;
  margin-bottom: -100px;
  background: url(../images/popup_bg.jpg) no-repeat center center;
  text-align: center;
  overflow: hidden;
  color: #FFF;
  border-radius: 10px;
}

.video-popup .bg-overlay{
  z-index: 1;
}
.video-popup .zindex-fixed{
  z-index: 99;
}
.video-popup a{
  color: #FFF;
}
.img-icon img{
  max-width: 100px;
  border-radius: 50%;
  box-shadow: var(--shadow-primary);
  border: 5px solid #FFF;
  margin-right: 25px;
}
.img-icon h3{
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 0;
}
.img-icon h3 span{
  display: block;
  font-size: 0.95rem;
  color: darken(#999, 20%);
  font-weight: 400;
  padding-top: 0.2rem;
}

.bordered-img{
  box-shadow: var(--shadow-primary);
  border: 10px solid #FFF;
}

/*------------------------------------------*/
/*    Client Testimonials
/*------------------------------------------*/
#home-client-testimonials {
  margin-top: -30px;
}
#home-client-testimonials .item{
  padding: 30px;
}
.client-testimonial{
  box-shadow: 0px 8px 37px 0px rgba(0, 0, 0, 0.14);
  padding: 40px 20px;
}
.client-testimonial .media{
  display: flex;
  align-items: flex-start;
}
.client-testimonial .client-testimonial{
  flex-shrink: 0;
}
.client-testimonial .client-inner-content{
  flex-grow: 1;
}
.client-testimonial-icon{
  max-width: 100px;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
}
.client-testimonial-icon:after{
  position: absolute;
  bottom: -5px;
  left: 50%;
  content: "\efcd";
  font-size: 36px;
  font-family: IcoFont;
  color: #FFF;
  line-height: 0.833;
  z-index: 1;
  transform: translateX(-50%);
}
.client-testimonial-icon img{
  opacity: 0.5;
}
.client-inner-content{
  font-size: 0.875rem;
  color: rgb(159, 159, 159);
  line-height: 1.571;
}
.client-inner-content .blockquote-footer,
.client-inner-content .blockquote-footer cite{
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  color: var(--secondary-color);
  margin-top: 1rem;
  line-height: 1.375;
}
.client-testimonials-bg{
  background-image: url(../images/bg_2.jpg);
  background-repeat: no-repeat;
  padding: 3rem;
  text-align: center;
  color: #FFF;
  overflow: hidden;
}
.client-testimonials-bg .owl-carousel .owl-nav button.owl-prev,
.client-testimonials-bg .owl-carousel .owl-nav button.owl-next{
  color: #FFF;
}
.client-testimonials-bg .client-inner-content,
.client-testimonials-bg .client-inner-content .blockquote-footer,
.client-testimonials-bg .client-inner-content .blockquote-footer cite{
  color: #FFF;
}
/*------------------------------------------*/
/*    Blog Styles
/*------------------------------------------*/
.blog-warp{
  text-align: center;
  font-size: 14px;
  line-height: 1.714;
}
.blog-warp:hover img{
  opacity: 0.7;
  transform: translateY(-3px);
}
.blog-warp img{
  margin-bottom: 22px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.blog-warp h4 a{
  font-size: 1.125rem;
  color: #333;
  font-weight: 700;
  line-height: 1.2;
}
.blog-warp h4 a:hover{
  color: var(--primary-color);
}
.meta-box{
  margin-bottom: 15px;
  font-size: 0.95rem;
  font-weight: 500;
}
.meta-box span{
  margin: 0 4px;
}
.meta-box a{
  color: var(--primary-color);
}
.meta-box a:hover{
  color: var(--main-dark-color);
}
.blog-list-footer ul li{
  margin-bottom: 30px;
}
.blog-list-footer ul li:last-child{
  margin: 0;
}
.blog-list-footer .media{
  display: flex;
}
.post-thumb{
  width: 80px;
  flex-shrink: 0;
  margin-right: 25px;
}
.post-text{
  flex-grow: 1;
}
.post-text a{
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
}
.post-text a:hover{
  color: #333;
}
.comment-box{
  font-weight: 500;
  font-size: 0.95rem;
}
.comment-box a{
  color: #06163a;
  font-size: 0.95rem;
}
.comment-box a:hover{
  color: var(--primary-color);
}
.comment-box span{
  padding-right: 15px;
}
.comment-box span i{
  padding-right: 5px;
}
.comment-box span:last-child{
  padding: 0;
}

/*------------------------------------------*/
/*    Blogs Pages Start
/*------------------------------------------*/
.blog-list [class*="col-"]{
  margin-bottom: 40px;
}
.sidebar-spacer{
  padding-left: 1.5rem;
}
.widget-wrap{
  margin-bottom: 60px;
}
.widget-wrap h3{
  color: var(--secondary-color);
}
.blog-list-categories a {
  padding: 0.5rem 1.45rem;
  display: block;
  font-size: 0.875rem;
  color: var(--secondary-color);
  position: relative;
  z-index: 1;
  border-left: 1px solid #eae6e6;
  font-weight: 600;
}
.blog-list-categories a:hover{
  color: var(--primary-color);
  padding: 0.5rem 1.45rem 0.5rem 1.75rem;
}
.blog-list-categories a:after{
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--primary-color);
  content: "";
  left: 0;
  top: 0;
  z-index: -1;

  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.blog-list-categories a:hover:after,
.blog-list-categories li.active a:after{
  width: 2px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

/* Blog Standard */
.blog-standard{
  margin-bottom: 70px;
}
.blog-standard:last-child{
  margin-bottom: 0;
}

.theme-pagination .page-item .page-link{
  border-radius: 3px !important;
  margin-right: 12px;
  color: #28324c;
  border-color: #ececec;
  background: #fafafa;
}

.theme-pagination .page-item.active .page-link,
.theme-pagination .page-item .page-link:hover {
  z-index: 1;
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link{
  background: #ebebeb;
  border-color: #ebebeb;
}

/* Blog Single */
.tags a {
  display: inline-block;
  color: #FFF;
  padding: 0.7rem 1.9rem;
  font-weight: 500;
  background: var(--secondary-color);
  margin: 0 10px 8px 0;

  border-radius:40px;
  -moz-border-radius:40px;
  -webkit-border-radius:40px;
}
.tags a:hover{
  background:var(--primary-color);
  color:#FFF;
}
.share-this a{
  display: inline-block;
  color: #FFF;
  width: 28px;
  height: 28px;
  text-align: center;
  background: red;
  align-items: center;
  display: flex;
  margin-left: 7px;
  justify-content: center;
}
.share-this a.ff{
  background: #627aad;
}
.share-this a.tw{
  background: #4d9ed8;
}
.share-this a.ln{
  background: #2f75ae;
}
.author-box{
  border: 1px solid #ececec;
  padding: 2rem
}
.author-box .media{
  display: flex;
}
.thumb{
  width: 90px;
  flex-shrink: 0;
  margin-right: 30px;
}
.border-style{
  margin-bottom: 15px;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0 10px 0;
}
.border-style h4, .border-style small{
  font-weight: 700;
}
.commnets-reply .media{
  display: flex;
}
.commnets-reply .thumb{
  flex-shrink: 0;
}
.commnets-reply .thumb img{
  box-shadow: 0px 6px 10px 0px rgba(254, 135, 4, 0.2);
  border: 4px solid #FFF;
}
.commnets-reply .media-body{
  flex-grow: 1;
}
.commnets-reply .btn-theme {
  font-size: 0.75rem;
  padding: 0.4rem 1.5rem;
}
/*------------------------------------------*/
/*    Contact Map Styles
/*------------------------------------------*/
.map-bg{
  position: relative;
  height: 400px;
}
.map-bg-with-contact{
  position: relative;
  padding: 100px 0;
}
.map-bg-with-contact #map-holder{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.map-shadow{
  border-style: solid;
  border-width: 4px;
  border-color: rgb(255, 255, 255);
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 7px 46px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: -120px;
}
.map-shadow #map-holder{
  height: 350px !important;
}
.map-bg iframe,
.map-bg-with-contact #map-holder iframe{
  margin:0;
  background:#FFF;
  padding:0;
  height: 100%;
  width: 100%;
}
#map_extended{
  height: 100%;
  left: 0;
  line-height: inherit;
  position: relative;
  top: 0;
  width: 100%;
}
#map_extended img {
  max-width: inherit;
}
#map_extended .gm-style div {
  font: 13px/20px 'Open Sans', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}
#map_extended .gmap_marker address {
  width: 300px;
  margin-bottom: 0;
}
#map_extended .gmap_marker address > div {
  width: 100%;
  display: inline-block;
}
#map_extended .gmap_marker address div div {
  float: right;
  width: 70%;
}
#map_extended .gmap_marker address div div:first-child {
  width: 30%;
  float: left;
}
#map_extended .gmap_marker h4{
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0.9rem;
}
/*------------------------------------------*/
/*    Contact Page Style
/*------------------------------------------*/
.contact-details-on-map-iframe {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  background: #FFF;
  padding: 2.5rem 1.15rem;
  color: #333;
  border-radius: 3px;
  transform: translateY(-50%);
}
.contact-details-on-map {
  background: #FFF;
  position: relative;
  z-index: 9;
  box-shadow: var(--shadow-secondary);
  padding: 1.5rem;
  color: #333;
  border-radius: 3px;
  max-width: 530px;
}
.contact-details-on-map h4{
  font-size: 16px;
  font-weight: 600;
  color: rgb(6, 22, 58);
  line-height: 1.714;
}
.contact-details-on-map p {
  color: #9b9b9b;
}
.contact-details-on-map a {
  color: rgb(6, 22, 58);
}
.contact-details-on-map i{
  color: var(--primary-color);
  font-size: 24px;
  top: 4px;
  padding-right: 10px;
  display: inline-block;
  position: relative;
}
.contact-details-on-map.standard{
  position: relative;
  left: auto;
  top: auto;
  padding: 0;
  transform: none;
  background-color: transparent;
  box-shadow: none;
}
.contact-full-shadow{
  box-shadow: 0px 18px 76px 0px rgba(0, 0, 0, 0.06);
  padding-bottom: 250px;
}
.contact-map-bg{
  position: absolute;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
}
.contact-map-bg.option{
  left: auto;
  top: 50%;
  transform: translate(0%,-50%);
  z-index: -1;
}
.contact-detail-shadow{
  border-radius: 8px;
  position: relative;
  z-index: 1;
  background-color: #FFF;
  box-shadow: 0px 18px 76px 0px rgba(0, 0, 0, 0.14);
  padding: 3rem;
}
.contact-detail-shadow .items{
  margin-bottom: 20px;
}
.contact-detail-shadow h4 {
  font-size: 0.875rem;
  color: var(--secondary-color);
  margin-bottom: 15px;
  font-weight: 700;
}
.contact-detail-shadow i {
  color: var(--primary-color);
  top: 3px;
  position: relative;
  font-size: 1.25rem;
  margin-right: 15px;
}
.contact-detail-shadow a{
  text-decoration: underline;
}
.free-quote-form.contact-page{
  margin-bottom: 0;
  margin-top: -140px;
}
.free-quote-form.contact-page .rounded-field input[type="text"],
.free-quote-form.contact-page .rounded-field input[type="email"],
.free-quote-form.contact-page .rounded-field select,
.free-quote-form.contact-page .rounded-field .nice-select,
.free-quote-form.contact-page .rounded-field textarea,
.contact-page-option.free-quote-form .rounded-field input[type="text"],
.contact-page-option.free-quote-form .rounded-field input[type="email"],
.contact-page-option.free-quote-form .rounded-field select,
.contact-page-option.free-quote-form .rounded-field textarea,
.contact-page-option.free-quote-form .rounded-field .nice-select {
  background-color: #fafafa;
}
.free-quote-form.contact-page-option .rounded-field .form-control:focus,
.free-quote-form.contact-page .rounded-field .form-control:focus {
  background-color: #fefefe;
}
.contact-detail-shadow.no-shadow{
  padding: 0;
  background: transparent;
  box-shadow: none;
}
.free-quote-form.contact-page-option{
  margin-bottom: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

/*------------------------------------------*/
/*    Request Page
/*------------------------------------------*/
.request_pag_img{
  min-height: 400px;
}
.request_popup .form-content{
  padding: 3rem;
}
.request_popup{
  /* max-width: 1170px; */
  border-radius: 10px;
  overflow: hidden;
}
.request_popup .modal-content{
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.request_popup .close {
  position: absolute;
  opacity: 1;
  color: var(--primary-color);
  top: 8px;
  right: 15px;
  z-index: 99;
  text-shadow: none;
  font-size: 1.5rem;
  font-weight: 700;
}
.request_popup .close:hover{
  transform: rotate(360deg);
}
.request_popup .bg-img-7{
  background-image: url(../images/bg_8.jpg);
  background-color: var(--secondary-color);
}
.center-head {
  color: #202120;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: lighter;
  margin: 10px 0 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.center-head:after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  border-bottom: 1px solid #ccc;
  top: 50%;
  /* margin-top: -1px; */
  z-index: 9;
}
.center-head span {
  display: inline-block;
  padding: 0 20px;
  position: relative;
  z-index: 99;
  font-weight: 600;
}
/*------------------------------------------*/
/*    Pricing Table Style
/*------------------------------------------*/
.pricing-table{
  text-align: center;
  background: var(--bg-light-gray);
  padding: 4rem 2rem;
}
.pricing {
  position: relative;
  font-size: 2.25rem;
  font-weight: 600;
  color: var(--primary-color);
  padding: 35px 15px 0;
  display: inline-block;
}
.pricing sup{
  font-size: 50%;
  color: #232323;
  padding: 0 0 0 4px;
}
.pricing-table ul li{
  padding: 10px 0;
  font-size: 1rem;
}
.pricing-table .service-icon {
  width: 105px;
  height: 105px;
  font-size: 2.825rem;
  margin-top: 10px;
}
.best-seller{
  background: #FFF;
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color);
  box-shadow: 0px 0px 52px 0px rgba(28, 28, 28, 0.09);
}
/*------------------------------------------*/
/*    Footer Style
/*------------------------------------------*/
.modal-open{
  padding: 0 !important;
}
footer{
  font-size: 0.95rem;
  color: #666;
  line-height: 1.714;
}
.logo-footer{
  width: 155px;
  margin-bottom: 25px;
}

.footer-heading {
  font-size: 1.125rem;
  color: #06163a;
  line-height: 1.714;
  margin-bottom: 20px;
  font-weight: 600;
}
footer .social-icons{
  font-size: 20px;
  color: rgb(6, 22, 58);
  text-transform: uppercase;
  line-height: 1.2;
}
footer .social-icons a{
  padding-right: 10px;
  color: rgba(6, 22, 58, 0.3);
}
footer .social-icons a:hover{
  color: var(--primary-color);
}
.copyright-wrap{
  margin-top: 40px;
  color: #FFF;
  font-size: 0.85rem;
  padding: 1rem 0;
}
.copyright-wrap a{
  color: var(--primary-color);
}
.copyright-wrap a:hover{
  color: var(--bg-white);
}
.copyright-wrap span{
  padding: 0 9px;
}
.copyright-wrap span#yearText {
  padding: 0;
}
.copyright-links a{
  color: #FFF;
}
.copyright-links a:hover{
  color: var(--primary-color);
}

/*------------------------------------------*/
/*    On Scroll Header Animaion
/*------------------------------------------*/
.dropdown-submenu a.dropdown-submenu-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 991.99px) {
  .dropdown-submenu .dropdown-menu-right {
    left: auto;
    right: 100%;
  }
  .dropdown-menu.show {
    -webkit-animation: fadeIn 0.3s alternate;
    /* Safari 4.0 - 8.0 */
    animation: fadeIn 0.3s alternate;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }
  .dropdown-submenu .dropdown-menu-right {
    left: auto;
    right: 100%;
  }

  .nav-item.dropdown.dropdown-mega {
    position: static;
  }
  header{
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    transition: all 0.15s linear;
  }
  header.header-anim.fixed-top {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(63, 75, 75, 0.7);
    width: 100%;
    z-index: 11;

    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    transition: all 0.15s linear;
  }
  header.header-anim.fixed-top .navbar-brand{
    width: 120px;
  }

  .dropdown:hover > .dropdown-menu{
    margin-top: 1rem;
  }

  .dropdown:hover > .dropdown-menu,
  .dropdown-submenu:hover > .dropdown-menu {
    display: block;

    -webkit-animation-name: slideInDownMenu;
    -moz-animation-name: slideInDownMenu;
    -o-animation-name: slideInDownMenu;
    animation-name: slideInDownMenu;

    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  /* .navbar .dropdown-menu.fade-down{ top:10%; transform: rotateX(-75deg); transform-origin: 0% 0%; } */
  .navbar .dropdown-menu.fade-up.show{
    -webkit-animation-name: slideInDownMenu;
    -moz-animation-name: slideInDownMenu;
    -o-animation-name: slideInDownMenu;
    animation-name: slideInDownMenu;

    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes slideInDownMenu {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

  @-moz-keyframes slideInDownMenu {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

  @-o-keyframes slideInDownMenu {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

  @keyframes slideInDownMenu {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

}
/*---- Header Regular End ---*/
.header-fullpage{
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.header-fullpage.fixed{
  background: var(--bg-white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1009;
  box-shadow: var(--shadow-primary);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.header-fullpage.fixed .top-bar{
  height: 0px;
  overflow: hidden;
  opacity: 0;
  border: 0;
  padding: 0;
}
.header-fullpage.fixed.top-transparent{
  padding-bottom: 0;
}

.header-one .navbar.header-fullpage.fixed{
  background-color: rgba(var(--secondary-rgb), 0.75);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 0;
  box-shadow: none;
}
.header-fullpage.fixed .navbar-brand {
  width: 120px;
  padding: 0;
}

.header-two .navbar.fixed{
  box-shadow: none;
  background-color: rgba(var(--secondary-rgb), 0.75);
}

.header-three .fixed .navbar-brand{
  /* width: 140px;*/
  padding: 1rem;
}
.header-three .navbar.fixed{
  box-shadow: none;
  background-color: rgba(var(--secondary-rgb), 0.75);
  padding-bottom: 0.7rem;
}
.header-sixth .navbar.fixed{
  box-shadow: none;
  background-color: rgba(var(--secondary-rgb), 0.75);
}

.header-fullpage.fixed.navbar-expand-md .navbar-nav .nav-item,
.header-fullpage.fixed.navbar-expand-lg .navbar-nav .nav-item{
  padding-top: 1rem;
}
/* Header Style First Home Page */
.header-one{
  position: absolute;
  width: 100%;
  z-index: 1009;
}
.header-one .top-bar{
  background: var(--bg-light-theme);
  padding: 0.5rem 0;
}
.header-one .lang-toggle a.btn{
  background: transparent;
  border: 1px solid #333;
  padding: 0.5rem 0.8rem;
}
.header-one .top-text{
  font-size: 0.95rem;
  font-weight: 600;
}
.header-one .top-text:not(:last-child) {
  margin-right: 1.5rem;
}
.header-one .top-bar [data-bs-toggle="modal"] i,
.header-two .top-bar [data-bs-toggle="modal"] i,
.header-three .top-bar [data-bs-toggle="modal"] i{
  color: var(--bg-white);
}
.header-one .top-text a{
  color: var(--primary-color);
}
.header-one .navbar{
  background: transparent;
  padding: 0.5rem 0;
}
.header-one .request-btn a#search_home{
  color: var(--bg-white);
}
.header-one .request-btn a#search_home:hover{
  color: var(--primary-color);
}
/* Header Style First Home Page */

/* Header Style Second Home Page */
.header-two .navbar{
  background: var(--secondary-color);
}
.header-two  .top-text:not(:first-child) {
  margin-left: 2rem;
}
.header-two .lang-toggle a.btn{
  font-size: 1rem;
  border: 1px solid var(--secondary-color);
  padding: 0.5rem;
  display: inline-block;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
}
.header-two .top-bar {
  padding: 0.7rem 0;
}
/* Header Style Second Home Page */

/* Header Style Third Home Page */
.header-three{
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
}

.header-three .navbar{
  background: transparent;
}
.header-three .navbar-brand {
  background: #fef9f5;
  width: 180px;
  display: block;
  padding: 1.5rem 1.5rem;
}
.header-three .top-bar .social-icons a{
  color: var(--bg-white);
  font-size: 1.3rem;
  margin: 0 .4rem;
  transition: all .3s;
  text-decoration: none;
}
.header-three .top-bar .social-icons a i{
  color: var(--bg-white);
}
.header-three .top-bar{
  background: transparent;
  padding-bottom: 0;
  padding-top: 10px;
}
/* Header Style Third Home Page */

/* Header Style Fourth Home Page */
.header-fourth{
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1050;
}
.header-fourth .top-bar{
  color: var(--bg-white);
  background: transparent;
}
.header-fourth .top-bar .lang-toggle a.dropdown-toggle{
  color: #FFF;
}
.header-fourth .top-bar .lang-toggle a.dropdown-toggle:hover{
  color: var(--primary-color);
}
/* Header Style Fourth Home Page */

/* Header Style Sixth Home Page */
.header-sixth {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1050;

  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.header-sixth .navbar{
  background: transparent;
}
.header-sixth .request-btn a#search_home{
  color: var(--bg-white);
}
.header-sixth .request-btn a#search_home:hover{
  color: var(--primary-color);
}
/* Header Style Sixth Home Page */


/* Header With Top Bar Transparent */
.top-transparent nav{
  background: #FFF !important;
}
.navbar-expand-md .navbar-nav .nav-item,
.navbar-expand-lg .navbar-nav .nav-item{
  padding: 1rem 0.19rem;
}
.navbar-brand {
  padding: 0;
  width: 130px;
  margin: 0;
}

.navbar-nav .nav-link {
  color: rgba(6, 22, 58, 0.5);
}
.navbar-nav .active .nav-link,
.navbar-nav .active .nav-link:hover,
.navbar-nav .nav-item:hover > a,
.navbar-nav .nav-link:hover{
  background: #f4f4f4;
  color: var(--primary-color);
}
.navbar-nav .active .nav-link{
  color: var(--primary-color);
}
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:focus-visible,
.navbar-nav .nav-link:focus-within{
  outline: none;
  box-shadow: none;
}
.top-transparent .navbar-toggler {
  padding: 0;
  color: red;
}
.top-bar {
  color: #1f1f1f;
  font-size: 14px;
  background: #fafafa;
  padding: 0.3rem 0;
}
.top-bar i{
  color: var(--primary-color);
}
.top-bar .top-text small{
  color: rgba(6, 22, 58, 0.5);
}
.top-border .lang-toggle a.btn {
  background: var(--bg-light-gray);
}
.header-logo-top.navbar-nav .nav-link{
  color: #FFF;
}
.header-logo-top .request-btn a#search_home{
  color: #FFF;
}
/* Header With Top Bar Transparent */

/* Header With Top Bordered */
.bordered-nav .top-bar {
  padding: 1rem 0;
  background: #fef9f5;
  color: #000;
}
.bordered-nav.top-transparent nav {
  background: transparent !important;
}
.bordered-nav .navbar-brand{
  background: transparent;
  padding: 0;
}
.header-6.bordered-nav .navbar-expand-md .navbar-nav .nav-item,
.header-6.bordered-nav .navbar-expand-lg .navbar-nav .nav-item {
  padding: 1rem 0rem;
}
.bordered-nav .top-text{
  margin-right: 1.5rem;
}
.top-text small{
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  color: #06163a;
}
.bordered-nav .top-text a{
  color: var(--primary-color);
}
.top-text a:hover{
  text-decoration: underline;
}
.bordered-nav.header-fullpage.fixed .navbar-brand {
  padding: 0;
}
.bordered-nav.header-fullpage.fixed {
  padding: 0.2rem 0;
}
.bordered-nav.header-fullpage.fixed .navbar .bdr-nav {
  border-width: 0;
  border-style: solid;
  border-color: rgba(255,255,255,.2);
  padding-top: 0;
}
@media (min-width: 991.99px) {
  .bordered-nav .request-btn a#search_home {
    font-size: 1.125rem;
    color: #FFF;
    padding-right: 0;
    padding-top: 0;
  }

  .header-6.bordered-nav .request-btn a#search_home{
    padding: 1rem;
  }
}
/* Header With Top Bordered */

/*------------------------------------------*/
/*    Top Bar Right Elements
/*------------------------------------------*/

.request-btn a{
  border-radius: 4px;
  padding: 0.53rem 1rem;
  white-space: nowrap;
  color: #06163a;
  font-weight: 600;
}
.top-bar .social-icons{
  margin: 0 .5rem
}
.top-bar .social-icons a{
  color: #f1fbff;
  font-size: 1.3rem;
  margin: 0 .4rem;
  transition: all .3s;
  text-decoration: none;
}
.top-bar .social-icons a:hover{
  color: var(--primary-color);
}
.lang-toggle .dropdown-toggle {
  padding: 0.5rem 0;
}
.lang-toggle a.btn {
  background: var(--bg-white);
  font-size: 0.875rem;
  border-radius: 0;
  border-radius: 3px;
  display: flex;
  box-shadow: none;
  align-items: center;
}
.lang-toggle:hover a{
  color: var(--secondary-color);
}
.lang-toggle a.btn i{
  padding-left: 4px;
  position: relative;
  top: 1px;
}

.lang-toggle a.dropdown-toggle:after{
  display: none;
}
.lang-toggle .dropdown-menu{
  min-width: 150px;
  padding: 10px 0;
  top: 1.5rem;
}
.header-two .lang-toggle .dropdown-menu {
  top: 1.2rem;
}
.header-three .lang-toggle a.btn{
  padding: 0.5rem 0.8rem;
}
.lang-toggle .dropdown-menu a{
  padding: 0.4rem 0.9rem;
  font-weight: 500;
  border: none;
  font-size: 0.85rem;
  color: #333;
  text-transform: capitalize;
}

.lang-toggle .dropdown-menu a:hover{
  color: var(--primary-color);
}
.request-btn a#search_home {
  font-size: 1.125rem;
  color: var(--primary-color);
  padding-left: 0;
  padding-right: 0;
  margin: 0 1rem;
  align-items: center;
  display: flex;
}
/* Top Bar Right Elements */


/* Home Page Second Style */
.home-welcome{
  margin-top: -90px;
}
/* Home Page Second Style */


/*------------------------------------------*/
/*    What We Offer Section
/*------------------------------------------*/
.what-we-offer{
  background-image: url(../images/what_we_offer.jpg);
  box-shadow: 0px 18px 76px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 99;
}
/* What We Offer Section */

/*------------------------------------------*/
/*    Page Breadcrumbs Style
/*------------------------------------------*/
.breadcrumbs-page{
  background-image: url(../images/breadcrumbs_bg.jpg);
  background-repeat: no-repeat;
  padding: 7rem 0;
  background-position: left center;
}
.breadcrumbs-page-2{
  background-image: url(../images/breadcrumbs_bg2.jpg) !important;
}
.breadcrumbs-page-3{
  background-image: url(../images/qor/ip-webster-intro.jpg) !important;
}
.breadcrumbs-page .breadcrumb{
  background: transparent;
  color: #FFF;
  padding: 0;
  font-size: 0.95rem;
  font-weight: 500;
}
.breadcrumbs-page .breadcrumb a,
.breadcrumbs-page .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-item.active{
  color: #FFF;
}
.breadcrumbs-page .breadcrumb-item+.breadcrumb-item::before {
  top: 1px;
  position: relative;
}
.breadcrumbs-page h1 {
  color: #FFF;
}
.breadcrumbs-description{
  padding: 0.5rem 1.5rem;
  color: #FFF;
  border-left: 2px solid var(--primary-color);
  max-width: 50%;
  margin-top: 15px;
}
/* Page Breadcrumbs End */

/*------------------------------------------*/
/*    Video Popup Style
/*------------------------------------------*/
.video-icon{
  position:absolute;
  left:50%;
  margin:-35px 0 0 -35px;
  top:50%;
}
.video-box {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990000;
  display: none;
  position: fixed;
  overflow: hidden;
  background-color: #000000;
}
.video-box iframe {
  float: left;
  width: 100%;
  height: 100%;

  -webkit-border: 0;
  -moz-border: 0;
  -o-border: 0;
  border: 0;
}
.video-box .close-video {
  top: 17px;
  left: 17px;
  width: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 25px;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  position: absolute;
  background-color: #59585b;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.video-box .close-video:hover {
  color: #21252b;
  background-color: #ffffff;
}
/*------------------------------------------*/
/*    Footer Widgets
/*------------------------------------------*/
.footer-widget-menu ul li{
  padding: 0px 0 14px 0;
}
.footer-widget-menu ul li *{
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.footer-widget-menu ul li a span{
  font-weight: 500;
}
.footer-widget-menu ul li a i{
  padding: 0 10px 0 0;
  color: var(--primary-color);
  text-decoration: none !important;
}
.footer-widget-menu ul li a:hover span{
  text-decoration: none;
  color: var(--primary-color);
}
.footer-widget-menu ul li a:hover i{
  padding: 0 10px;
}
.footer-widget-contact .media{
  display: flex;
}
.footer-widget-contact i{
  color: #99a0a3;
  font-size: 24px;
  margin-top: 4px;
  flex-shrink: 0;
}
.footer-widget-contact a{
  color: var(--primary-color);
  font-weight: 500;
}
.footer-widget-contact a:hover{
  color: var(--main-dark-color);
}
.footer-widget-tweet i{
  color: #99a0a3;
  margin-right: 18px;
}
.footer-widget-tweet .btn-theme{
  background: var(--secondary-color);
  font-size: 13px;
  margin-top: 30px;
}
.footer-widget-tweet .btn-theme i{
  margin: 0;
  color: #FFF;
}
/*------------------------------------------*/
/*    Miscellenours
/*------------------------------------------*/
#body-content{
  overflow-y: hidden;
}
.bs-docs-grid .col-md-1 span,
.bs-docs-grid .col-md-2 span,
.bs-docs-grid .col-md-3 span,
.bs-docs-grid .col-md-4 span,
.bs-docs-grid .col-md-5 span,
.bs-docs-grid .col-md-6 span,
.bs-docs-grid .col-md-4 span,
.bs-docs-grid .col-md-8 span,
.bs-docs-grid .col-md-9 span,
.bs-docs-grid .col-md-10 span {
  background-color: #f4f4f4;
  display: block;
  line-height: 40px;
  margin-bottom: 20px;
  min-height: 40px;
  font-size: 0.775rem;
  text-align: center;
}
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}
.cbp-l-grid-work .cbp-item-wrapper,
.cbp-l-grid-mosaic-projects .cbp-item-wrapper {
  background: #FFF;
  padding: 10px;
  box-shadow: none;
  text-align: center;
  border: none;
}
.cbp-l-grid-work-title,
.cbp-l-grid-work-desc{
  display: none;
}
.gallery-link{
  position: absolute;
  z-index: 999;
  right: -70px;
  top: -2px;

  background: var(--primary-color);

  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;

}

.gallery-link a{
  padding: 10px 15px;
  display: inline-block;
}
.gallery-link a:hover{
  color: #000;
}
.cbp-item-wrapper:hover .gallery-link{
  right: -3px;
}

label.error {
  font-size: 12px;
  color: #F00;
  font-weight: 300;
  padding: 0;
  margin: 10px 0 0 0;
}

/* SLIDER */
.home.slider {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  display: flex;
  position: relative;
  z-index: 0;
  background: #405089;
}
.home.slider .swiper-slide {
  overflow: hidden;
  color: #fff;
}
.home.slider .swiper-slide h6 {
  font-size: 1.3rem;
  font-weight: 400;
}
.home.slider .swiper-slide h2 {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 20px;
}
.home.slider .swiper-slide h2 span {
  color: #f65935;
  font-family: Georgia;
}
.home.slider .swiper-slide p {
  max-width: 600px;
  display: inline-block;
  margin-bottom: 40px;
}
.home.slider .swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.home.slider .swiper-custom-pagination {
  width: 100%;
  max-width: 1210px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  margin-top: -150px;
  display: flex;
  flex-wrap: wrap;
}
.home.slider .swiper-pagination-bullet {
  width: auto;
  height: auto;
  flex: 1;
  background: transparent;
  opacity: 0.4;
  border-radius: 0;
  padding: 15px 0;
  color: #fff;
  border-bottom: 1px solid #fff;
  line-height: 1;
}
.home.slider .swiper-pagination-bullet span {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  float: left;
  margin-top: 5px;
}
.home.slider .swiper-pagination-bullet-active {
  opacity: 1;
}

.slide-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  text-align: left;
}

.nice-select {
  float: none;
  line-height: inherit;
  height: auto;
  padding: 0.661rem .75rem;
  border-radius: 0;
}
.nice-select::after {
  height: 8px;
  right: 20px;
  width: 8px;
}

.form-control:focus{
  background: #FFF;
  box-shadow: none;
}
.form-control:disabled, .form-control[readonly] {
  opacity: 0.6;
}

.b-settings-panel {
  width:126px;
  position:fixed;
  top:50%;
  left:-126px;
  z-index:1000;
  padding:15px;
  background:#efefef;
  transform: translateY(-50%);
  border-right:none;
  color:#676767;
  -webkit-transition:left 0.2s ease-in-out;
  transition:left 0.2s ease-in-out;
}
.b-settings-panel:after {
  content:'';
  display:block;
  width:2px;
  position:absolute;
  top:-1px;
  right:0;
  bottom:-1px;
}
.b-settings-panel.active {
  left:0px;
}
.btn-settings {
  width:40px;
  height:40px;
  position:absolute;
  top:-1px;
  left:100%;
  background:#404347;
  border-radius:0 3px 3px 0;
  cursor:pointer;
  text-align:center;
  font:24px/40px 'FontAwesome';
  color:#fff;
}
.btn-settings:before {
  content:"\f1de";
}
.settings-section {
  padding:0px;
}
.b-settings-panel span {
  vertical-align:top;
  text-align:left;
}
.b-switch {
  display:inline-block;
  width:40px;
  height:24px;
  position:relative;
  margin:0 9px;
  padding:1px;
  border-radius:14px;
  border:1px solid #ddd;
  background:#fff;
  box-shadow:inset 0 1px 3px #f3f3f3;
}
.switch-handle {
  width:20px;
  height:20px;
  position:absolute;
  top:1px;
  right:26px;
  margin-right:-25px;
  border-radius:50%;
  box-shadow:0 1px 2px #c4c4c4;
  background:#f9c56a;
  cursor:pointer;
  -webkit-transition:right 0.2s ease-in-out;
  transition:right 0.2s ease-in-out;
}
.switch-handle:before {
  content:'';
  display:block;
  margin:7px auto;
  width:6px;
  height:6px;
  border-radius:50%;
  background:#fff;
}
.switch-handle.active {
  right:100%;
}
.color-list {
  padding-bottom:0;
  font-size:0;
  letter-spacing:-.31em;
  word-spacing:-.43em;
}
.color-list div {
  display: inline-block;
  width: 129px;
  margin: 4px;
  cursor: pointer;
}
.color-list div.active {
  opacity:1;
  position:relative;
}
.color-list .items img{
  transition:all 0.2s ease-in-out;
  height: 37px;
}
.color-list .items.active img{
  transform: translateX(4px);
  transition:all 0.2s ease-in-out;
}
.color-list div.active::before {
  font: 16px / 30px FontAwesome;
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  text-align: center;
  z-index: 100;
  color: rgb(255, 255, 255);
  top: 2px;
  left: 4px;
}
.b-settings-panel h5 {
  margin-left:3px;
  margin-bottom:13px;
  text-align:left;
  font-size:13px;
}
