@import "qor-bootstrap";

@import "assets/scss/theme-plugin";

@import "assets/scss/theme-style";

@import "assets/scss/theme-responsive";

button.disabled{
  background-color: lightgray !important;
  cursor: not-allowed !important;
}


.toast-position{

}
