/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1921px) {
}

/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */



@media (min-width: 1200px) {
  /* .navbar-brand { background: #fef9f5; width: 180px; padding: 1.5rem 1.5rem; } */
}

/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 992px) and (max-width: 1199.99px) {

  html { font-size: 15px; }
  .overlay-hugeinc form .form-inner-div { padding-right: 7px; }

  .slider .swiper-custom-pagination { padding: 0 15px; }

}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 768px) and (max-width: 991.99px) {

  html { font-size: 15px; }

  footer .social-icons{ margin-bottom: 20px; }
  .why-choose [class*="col-"] { margin-bottom: 30px; }
  .why-choose [class*="col-"]:last-child { margin-bottom: 0px; }

  /* Blog Sidebar */
  .sidebar-primary div.widget-wrap:last-child,
  .sidebar-secondary div.widget-wrap:last-child{ margin-bottom: 0!important; }

}

/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 991px) {
  .dropdown-menu.show {
    display: none;
  }
  .dropdown-menu[data-bs-popper] {
    top: auto;
    left: 0;
    margin-top: 0;
  }
  .navbar-expand-md .navbar-nav .nav-item,
  .navbar-expand-lg .navbar-nav .nav-item {
    padding: 1rem 0;
    margin: 0 0.19rem;
  }
}

/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 991px) {
  .offcanvas-backdrop.show {opacity: 0.8; background-color: #06163a; }
  .offcanvas-header{ padding-bottom: 0; width: 100%; justify-content: space-between; }
  .offcanvas-header .navbar-toggler{ font-size: 1.5rem; color: var(--secondary-color); }
  .b-settings-panel{display: none;}
  #body-content { overflow-x: hidden; }
  .home-welcome{ margin-top: -40px; }
  .request-btn a#search_home{ margin-left: auto; }
  .header-one .request-btn a#search_home { top: 2px; position: relative; padding: 0; }
  .img-business-man { position: relative; }
  .header-one .request-btn{ margin-left: auto; }
  .request_popup{ max-width: 80%; margin-left: auto; margin-right: auto; }
  .request-page-form{ padding: 0 2rem; }
  .request_popup .close{ color: #FFF; }
  .request_popup .form-content{ padding: 2rem ;}
  .fixed-top{ position: relative; background: #06163a !important;  }
  .navbar-toggler{ outline: none; padding: 0; outline: none; box-shadow: none; margin-left: 1rem; border: none; }
  .navbar-toggler:focus{ box-shadow: none; }
  .navbar-brand { margin: 0; }
  .navbar-expand-md .navbar-nav .nav-item, .navbar-expand-lg .navbar-nav .nav-item { padding: 0 0rem 0.3rem !important; }
  .navbar-nav .nav-link { display: flex !important; align-items: center; justify-content: space-between; }
  .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .nav-link{ padding: 0.6rem 0; }
  .navbar-nav .active .nav-link, .navbar-nav .active .nav-link:hover, .navbar-nav .nav-item:hover > a, .navbar-nav .nav-link:hover{ background-color: transparent; }
  .dropdown-menu.animated {-webkit-animation-duration: unset; animation-duration: unset; }
  .dropdown-menu a, .dropdown-menu li > a { border-bottom: 1px solid #ececec;}
  .navbar-collapse .dropdown-menu{ box-shadow: none !important; border-radius: 0; background: var(--bg-light-gray); }
  .navbar-nav .nav-link i.icofont-rounded-down{ transition: all 400ms ease-in-out; }
  .navbar-nav .nav-link.show i.icofont-rounded-down{ display: inline-block; transform: rotate(180deg); }
  .dropdown-menu:after{ display: none !important; }

  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show{ color: var(--primary-color); }

  .top-transparent .navbar-brand,
  .top-transparent.header-fullpage.fixed .navbar-brand { padding: 1rem 0 !important; width: 130px !important;}
  .top-transparent .top-bar { margin: 10px auto 0; text-align: center; }

  .bg-with-text{ padding: 2rem; min-height: 300px; }

  .forklift-image { position: relative; bottom: 0; left: 0; }
  .tracking { padding-bottom: 120px; }

  .center-text { padding: 1rem 2rem 0 0; }

  .map-bg-with-contact #map-holder { top: 0; left: 0; width: 100%; transform: none; position: relative; height: 300px; margin-top: -1.75rem; }

  .map-bg-with-contact { padding: 40px 0 0;	}

  .map-shadow { margin: 20px 0 50px; }

  .footer-subscribe{ padding-bottom: 0 !important; }

  /* Breadcrumbs*/
  .breadcrumbs-page{ margin-top: 0!important; padding: 60px 0; }
  .breadcrumbs-description { max-width: 70%; }

  /* Blog Sidebar */
  .sidebar-spacer { padding-left: 0; margin-top: 70px; }

  /* Request Button */

  .cbp-caption-activeWrap { background-color: var(--primary-color); }

  .bordered-nav .top-bar{ display: none !important; }
  .bordered-nav .navbar .bdr-nav{ padding: 1rem 0; }

  .bordered-nav .request-btn a#search_home { color: #FFF; }

  .header-6.bordered-nav .request-btn a#search_home { align-items: center; justify-content: center; display: flex; }
  .slider .swiper-custom-pagination { display: none; }

  .slider .swiper-slide h2 { font-size: 46px; }

  .swiper-button-prev { display: none; }

  .swiper-button-next { display: none; }

  /* Header Style First Home Page */
  .header-one{ position: relative; background: var(--secondary-color); }
  .header-one .navbar-toggler, .header-two .navbar-toggler{ padding: 0; margin-left: 0; }
  .header-one .navbar-toggler .icon-bar, .header-two .navbar-toggler .icon-bar{ background: var(--bg-white); }

  /* Header Style Second Home Page */
  .header-two .top-bar { padding: 10px 0; }
  .header-two .navbar{ padding: 10px 0; }
  .header-two .top-text{ display: none; }

  /* Header Style Third Home Page */
  .header-three{ position: relative; }
  .header-three .col-lg-auto{  width: 100%; }
  .header-three .top-bar{ display: none; }
  .header-three .navbar-brand { background: transparent; padding: 0; width: 130px; }
  .header-three .navbar-nav .nav-link { color: var(--main-dark-color); padding-left: 0; padding-right: 0; }
  .header-three .nav-light .navbar-nav .active .nav-link,
  .header-three .nav-light .navbar-nav .active .nav-link:hover,
  .header-three .nav-light .navbar-nav .nav-item:hover > a,
  .header-three .nav-light .navbar-nav .nav-link:hover{ color: var(--primary-color); }

  .header-three .navbar.fixed{ background-color: rgba(255, 255, 255, 0.9); }
  .header-three .fixed .navbar-brand{ padding: 0; }

  /* Header Style Fourth Home Page */
  .header-fourth{ position: relative; }
  .header-fourth .top-bar, .header-fourth .top-bar .lang-toggle a.dropdown-toggle { color: #1f1f1f}

  /* Header Style Sixth Home Page */
  .header-sixth{ position: relative; background: var(--secondary-color); }
  .header-sixth .navbar-toggler .icon-bar { background: var(--bg-white); }

  /* header, header .navbar{ animation: none !important; } */
  header .navbar{ padding: 10px 0; }

  .header-one .navbar { padding: 1rem 0; }

  .offcanvas{ height: 100vh; }
}

@media (max-width: 767px) {

  html { font-size: 15px; }

  .navbar-brand, .header-fullpage.fixed .navbar-brand { width: 110px; }
  .top-bar-btn.social-icons{ display: none !important; }

  .top-bar { text-align: center;}

  .heading-main { margin-bottom: 0; font-size: 2.75em; line-height: 3rem; }

  #js-styl2-mosaic{ margin-top: 1.5rem; }
  .video-popup { margin-bottom: -50px; }

  #body-content [class*="col-"], footer [class*="col-"] { margin-bottom: 40px; }
  #body-content [class*="col-"]:last-child, footer [class*="col-"]:last-child, #body-content .overlay [class*="col-"] { margin-bottom: 0; }

  .img-business-man{ display: none; }

  .copyright-links{ margin-bottom: 10px !important; }

  .home-welcome { margin-top: 0; padding: 50px 0 0 0 !important; }

  .btn-center { margin-top: 0; }

  /* Blog Sidebar */
  .sidebar-spacer { margin-top: 40px; }
  #body-content [class*="col-"].sidebar-primary,
  #body-content [class*="col-"].sidebar-secondary { margin-bottom: 0 !important; }

  .logo-footer { width: 120px; }

  .header-logo-top .request-btn { margin-left: 0; }
  .header-logo-top .request-btn a#search_home{ padding-left: 0; }

  .header-logo-top.top-transparent .navbar-toggler { margin-right: 10px; }

  /* Header Style First Home Page */
  /* .header-one{ padding: 0 15px; animation: none; } */
  /* .header-one .navbar{ animation: none; } */
  .header-one .navbar.header-fullpage.fixed{ padding: 0.7rem 0; }
  .header-one .top-bar{ display: none !important; }

  .why-choose .contact-map-bg img{ max-width: inherit; }

  .wide-tb-100, .wide-tb-70, .wide-tb-80 { padding-top: 50px; padding-bottom: 50px; }

  .tracking.parallax{ padding-bottom: 50px; }

}

@media (min-width: 576px) and (max-width: 767.99px) {

  #body-content .mb-spacer-md *[class*="col-"]{ margin-bottom: 0; }
  .mb-spacer-md .heading-main { margin-bottom: 70px; }

  /* Header H5 */
  h5.h5-xs { font-size: 1rem; }     /* 15px */
  h5.h5-sm { font-size: 1.05rem; }  /* 15.75px */
  h5.h5-md { font-size: 1.05rem; }  /* 15.75px */
  h5.h5-lg { font-size: 1.08rem; }  /* 16.2px */
  h5.h5-xl { font-size: 1.08rem; }  /* 16.2px */

  /* Header H4 */
  h4.h4-xs { font-size: 1.08rem; }  /* 16.2px */
  h4.h4-sm { font-size: 1.08rem; }  /* 16.2px */
  h4.h4-md { font-size: 1.15rem; }  /* 17.25px */
  h4.h4-lg { font-size: 1.23rem; }  /* 18.45px */
  h4.h4-xl { font-size: 1.3rem; }   /* 19.5px */

  .overlay-hugeinc form .form-inner-div { padding-right: 15px; }





}

@media (max-width: 575px) {

  .top-bar{ display: none; }
  .header-logo-top.top-transparent .navbar-toggler { margin-right: 15px; }
  .navbar{ padding: 0; }

  .owl-carousel .owl-nav button.owl-next { right: -10px; }
  .owl-carousel .owl-nav button.owl-prev { left: -18px; }
  .about-bg { background-position: 0; background-size: contain; }
  .style-one { margin-bottom: 0;  padding: 0 2rem 0; }

  .overlay-hugeinc form .form-inner { width: 92%; }
  .overlay-hugeinc form .form-inner-div{ padding: 0.75rem 1rem; }

  .footer-subscribe .head { text-align: center; margin-bottom: 15px; }
  .footer-subscribe .head span { display: inline !important; }

  .center-text { padding: 1rem 0 1.5rem; }

  .heading-main { font-size: 2.25rem; line-height: 2.45rem; }
  .client-testimonials-bg { padding: 1rem; }

  /* Breadcrumbs*/
  .breadcrumbs-description { max-width: 100%; padding: 0 1.5rem; }

  /* Request Button */
  .request-btn a.icon-left i {padding: 0;}
  .request_popup { max-width: 90%;}


  .contact-details { padding: 2.5rem 0rem; }

  .about-whoose{ padding: 10px !important; padding-right: 0 !important; }

  .icons-listing.w-half li { flex: 1 1 100%; }

  .header-6 .btn-theme{ margin: 0 !important; }
  .header-6.bordered-nav .navbar-brand { margin: 0; }
  .header-6 .navbar-toggler { padding: 20px 10px; }
  .header-6.bordered-nav .request-btn a#search_home{ margin-right: 0 !important }

  .slider .swiper-slide h2 { font-size: 2.5rem; }
  .slider .swiper-slide h6 { font-size: 1.2rem; }

  .tracking{ display: inherit; text-align: center; }
  .tracking .btn{ margin: 10px 0 0 0 !important; }

  .request-page-form .wide-tb-90{ padding-top: 0; padding-bottom: 40px; }

  .contact-map-bg.option { top: 30%; }

  .footer-subscribe .flex-nowrap { margin: 0 !important; }

  .home.slider .swiper-custom-pagination{ margin-top: -100px; }
  .home.slider .swiper-pagination-bullet { line-height: 1; align-self: flex-end; display: flex; flex-direction: column; margin: 0 7px; letter-spacing: -0.05rem; }
  .home.slider .swiper-pagination-bullet span { font-size: 0.875rem; text-overflow: ellipsis; }

  .offcanvas.offcanvas-start { width: calc(100% - 80px); max-width: calc(100% - 80px); }
}

@media (max-width: 480px) {

  .contact-details .col + .col{ margin-top: 25px; }
  .free-quote-form.contact-page .form-row,
  .free-quote-form.contact-page-option .form-row{ margin-bottom: 0 !important; }
  .free-quote-form.contact-page .form-row > .col,
  .free-quote-form.contact-page-option .form-row > .col{ flex-basis: auto; margin-bottom: 20px; }

  .client-testimonial .media{ display: inline; }

  .client-testimonial-icon { margin: 0 auto 25px; }
  .client-inner-content { text-align: center; }

  .mobile-100 a { display: inline-block; margin: 0 0 15px 0 !important; }
  .mobile-100 a.play-video{ margin: 0 !important; display: block; }

}

@media (max-width: 359px){
  .header-6.bordered-nav .navbar-brand { width: 130px; }

  .offcanvas.offcanvas-start { width: calc(100% - 40px); max-width: calc(100% - 40px); }
}
