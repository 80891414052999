// Define Custom Colors
$primary: #373863;
$secondary: #e45d49;
$tertiary: #f2a33f;

// Add custom colors to theme colors
$custom-colors:(
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary
);

$theme-colors: map-merge($theme-colors, $custom-colors);


// Theme color changes
:root {
  --primary-color: #373863;
  --secondary-color: #e45d49;
  --tertiary-color: #f2a33f;
  --main-dark-color: #f2a33f;
  --bg-light-theme: #E7E7E7;
  --bg-white: #FFF;
  --bg-light-gray: #fafafa;
  --shadow-primary: 0px 6px 10px 0px rgba(228, 93, 73, 0.2);
  --shadow-secondary: 0px 6px 10px 0px rgba(55,56, 99, 0.2);
  --shadow-tertiary: 0px 6px 10px 0px rgba(242,163,63,0.2);
  --secondary-rgba: rgba( 55, 66, 99, 0.8);
  --secondary-rgb: 55, 66, 99;
}
