/**** START Bootstrap Theme ****/

// Required
@import "functions";
@import "variables";
@import "variables-dark";

// Custom Theming
@import "qor-variables";

// Remainder of bootstrap imports
@import "maps";
@import "mixins";
@import "root";

// Layout and Components
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Optionally include any other parts as needed
@import "utilities";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "helpers";

// Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "utilities/api";

/**** END - Bootstrap Theme ****/
